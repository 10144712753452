import { useRouter } from "next/router"
import { useEffect } from "react"

const langs = {
    "en-US": "en_US",
    "en": "en_US",
    "es-ES": "es_ES",
    "es": "es_ES",
    "pt-BR": "pt_BR",
    "pt": "pt_BR"
}
type LangKey = keyof typeof langs


const parseBrowserLang = (key: LangKey) => (langs[key] ?? "en_US")

const HomeIndex = () => {

    const router = useRouter()
    useEffect(() => {
        if ("language" in navigator) {
            const browserLang = navigator.language;
            const parsedLang = parseBrowserLang(browserLang as keyof typeof langs)
            router.push(`/${parsedLang}`)
            return;
        }
        router.push("/en_US")
    }, [])
    return <></>
}

export default HomeIndex